import { gql } from '@apollo/client';
export const ADMIN_ORDERS = gql`
  query ADMIN_ORDERS {
    AdminOrders {
      _id
      user {
        _id
        email
        basicInfo {
          name
        }
      }
      createdAt
      products {
        product {
            _id
            name
            type
            orderType
            priceType
        }
        quantity
      }
      info
    }
  }
`;

export const ADMIN_ORDER_REPRINT = gql`
  mutation ADMIN_ORDER_REPRINT($orderId: ID!) {
    AdminOrderReprint(_id: $orderId) 
  }
`;

export interface adminOrderReprintData {
  AdminOrderReprint: Boolean;
}

export interface adminOrderReprintVariables {
  orderId: string
}

