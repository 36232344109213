import { useCallback, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { Loading } from '.'

interface FilePickerProps {
    file?: File,
    onFileChanged: (file: File) => void,
}

export const FilePicker = (props: FilePickerProps) => {
    const onDrop = useCallback((acceptedFiles) => {
        // load file
        console.log('acceptedFiles',acceptedFiles)
        props.onFileChanged(acceptedFiles[0])
    }, [])

      const {getRootProps, getInputProps, isDragActive} = 
        useDropzone({
            onDrop, 
            accept: ['.json'],
            multiple: false
        })

    return (
        <div {...getRootProps()} className="flex flex-col rounded-md border-2 border-dashed min-h-fit ">
            <input {...getInputProps()} />
            {
                props.file ? 
                    <div className='text-center pb-2'>{props.file.name}</div> : ''
            }
            {
                isDragActive ?
                    <div className='h-16 font-thin'>Lascia qui il file...</div> :
                    <div className='h-16 font-thin'>Trascina qui il file, o clicca per selezionare il file</div>
            }
        </div>
    )
}