// views
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import {  Route, Routes, useNavigate } from "react-router-dom";
import { Navbar } from "../components/Navbar";
// import { appStorage } from "../storage";
import { USER_LOGGED } from "../storage/queries/userState";
import { DashView } from "../views/home/DashView";
import { OrdersView } from "../views/orders/OrdersView";
import { ProductsView } from "../views/products/ProductsView";
import { UsersView } from "../views/users/UsersView";

interface AdminProps { }

const Admin = (props: AdminProps) => {
  let navigate = useNavigate();
  const { data } = useQuery(USER_LOGGED);

  const initApp = async () => {
    if (!data || !data.userLogged) {
      return navigate('/auth')
    }
  };

  useEffect(() => {
    initApp();
  });

  return (
    <>
      <div
        className="relative min-h-screen flex flex-1"
      >
        <Navbar />
        <div className="w-full px-4 md:px-10 mx-auto pt-4 md:pt-20 min-h-screen max-h-screen">
          <Routes>
            <Route path="products/*" element={<ProductsView />} />
            <Route path="orders/*" element={<OrdersView />} />
            <Route path="users/*" element={<UsersView />} />
            <Route path="*" element={<DashView />} />
          </Routes>
        </div>
      </div>
    </>
  );
};

export { Admin };
