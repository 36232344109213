import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { loginEmail, requestCode } from "../../libs/auth";
import { Button, Loading, TextBox } from "../../ui";

const AutoLogin = () => {
  let navigate = useNavigate();
  let { email, code } = useParams();

  const [loginError, setLoginError] = useState<null | string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const login = () => {
    loginEmail(email || "", code || "").then((res) => {
      if (res.success) {
        console.log('succesfully logged in')
        return navigate('/admin')
      } else {
        console.log('error in login',res.error)
        if (res.error === "wrong or expired code") {
          setLoginError("Codice errato o scaduto")
        } else {
          setLoginError("Errore imprevisto, riprovare o contattare Pandoper")
        }
        setIsLoading(false);
      }
    })
  };

  useEffect(()=> {
    console.log('Logging....')
    login()
  },[])

  return (
    <>
      <div className="grid place-items-center h-screen">
        <div className="">
          <img className="max-h-64" src="/images/logo.png" alt="logo"/>
          <div className="text-center pt-2">
            {
              (loginError) ? <div className="flex flex-col text-red-500">
                {loginError}
                <Button color="white" onClick={() => window.location.href = "/"}>Login</Button>
                </div> : <div>"Login in corso..."</div>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export { AutoLogin };
