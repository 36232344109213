import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { CreateUserCard } from "../../components/User/CreateUser";
import { EditUserCard } from "../../components/User/EditUser";
import { ADMIN_USERS } from "../../storage/queries/user";
import { User } from "../../storage/types";
import { Button, TextBox } from "../../ui";
import { Table } from "../../ui/Table";

const tableColumns : TableColumn<any>[]= [
  {
    name: 'nome',
    selector: (row: User) => row.basicInfo.name,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'email',
    selector: (row: User) => {return (row.auth.isEmailVerified === true)? row.email : `${row.email} (non verificata)`},
    sortable: true,
    minWidth: '40%',
    style: { fontWeight: 600 },
  },
  {
    name: 'stato',
    selector: (row: User) => {return (row.auth.isActive === true)? 'Attivo' : `Non attivo`},
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'ultimo accesso',
    selector: (row: User) => (row.auth.lastLogin)? new Date(row.auth.lastLogin).toLocaleString("it-IT") : 'Mai',
    sortable: true,
    sortFunction: (a, b) => {
      const aDate = new Date(a.auth.lastLogin);
      const bDate = new Date(b.auth.lastLogin);
      return aDate.getTime() - bDate.getTime();
    },
    minWidth: '20%',
    style: { fontWeight: 600 },
  }
  
]

interface UsersViewProps {
    route?: any;
  }
  
export  const UsersView = (props: UsersViewProps) => {
  
    const { loading, error, data, refetch } = useQuery<{ AdminUsers: User[] }>(ADMIN_USERS, {
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
    });

    const [users, setUsers] = useState<User[]>([]) 
    const [ifCreateUser, setIfCreteUser] = useState<boolean>(false) 
    
    useEffect(() => {
      if (data?.AdminUsers) {
        setUsers(data.AdminUsers)
      }
      }, [data]);
  

    const [selectedUser, setSelectedUser] = useState<User>();

    if (error) return <div>Error: {error}</div>

    const rowSelected = (row: User) => {
      if (row._id !== selectedUser?._id) {
        setIfCreteUser(false)
        setSelectedUser(row)
      } else {
        setIfCreteUser(false)
        setSelectedUser(undefined)
      }
    }

    const onRemoveUser = () => {
      setSelectedUser(undefined)
      refetch()
    }

    const createUserButton = <Button color="blue" onClick={() => {setSelectedUser(undefined); setIfCreteUser(true)}}>Aggiungi utente</Button>

    return (
      <div className="flex space-x-2">
        <div className="container grow-0 w-full p-2">
          <Table 
            columns={tableColumns}
            data={users}
            height={'calc(100vh - 240px)'}
            title='Utenti'
            loading={loading}
            filterSelector={'email'}
            addElementComponent={createUserButton}
            onRowClick={(row : User) => {rowSelected(row)}}
            selectedRowId={selectedUser?._id}
          />
        </div>
        <div className="container w-1/4 p-2 border-2 rounded-md border-gialloLucin">
          {(ifCreateUser)? 
              <div className="">
                <CreateUserCard users={users} onCreate={refetch} onClose={() => {setIfCreteUser(false)}}/>
              </div>:
              (selectedUser)?
              <EditUserCard onRemove={onRemoveUser} users={users} key={selectedUser._id} userId={selectedUser._id}/>
              :null
          }
        </div>
      </div>
    );
  };
  
  