import { useCallback, useEffect, useState } from "react"
import { User } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useMutation, useQuery } from "@apollo/client"
import { ImagePicker } from "../../ui/ImagePicker"
import { ADMIN_USER, removeUserData, removeUserVariables, REMOVE_USER, editUserData, editUserVariables, EDIT_USER } from "../../storage/queries/user"

interface UserCardProps {
    userId: string;
    users: User[]
    onRemove: () => void
  }

export const EditUserCard = (props: UserCardProps) => {
    const { loading, error, data } = useQuery<{ AdminUser: User }>(ADMIN_USER, {
        fetchPolicy: 'cache-and-network',
        variables: {
            userId: props.userId,
        },
    });
    
    const userCheck = (): {success: boolean, message?: string} => {
        if (!user?.basicInfo || user.basicInfo.name === "") return {success: false, message: 'Nome utente errato'}
        if (!user.email || user.email === "") return {success: false, message: 'Email errata'}
        return {success: true}
    }

    const [removeUser, { error: removeUserError, loading: removeUserLoading }] = useMutation<
        removeUserData,
        removeUserVariables
    >(REMOVE_USER);


    const [updateUser, { error: updateUserError, loading: updateUserLoading }] = useMutation<
        editUserData,
        editUserVariables
    >(EDIT_USER);

    const [user, setUser]=useState(data?.AdminUser)
    const [isUpdated, setIsUpdated] = useState(false)
    const [image, setImage] = useState<File>()
    const [userError, setUserError] = useState('')

    useEffect(() => {
    if (data?.AdminUser) {
        setUser(data.AdminUser)
    }
    }, [data]);

    if (loading) return <div>loading</div>
    if (error) return <div>Error on query: {error}</div>
    if (!user) return <div>Something went wrong</div>

    const update = async () => {
        const {success, message} = userCheck()
        if (success) {
            const tmpUser : User = user
            setUserError('')
            const res = await updateUser({
                variables: {
                  userId: tmpUser._id,
                  user: {
                        basicInfo: {
                            name: tmpUser.basicInfo.name,
                        },
                        isActive: tmpUser.auth.isActive,
                  },
                },
              });
              if (res.errors) {
                  console.log('Something went wrong')
                  setUserError('Utente non aggiornato')
              } else {
                setIsUpdated(true)
              }
        } else {
            if (message) setUserError(message)
                else setUserError('Errore generico')
        }
    }

    const remove = async () => {
        await removeUser({
            variables: {
                userId: props.userId,
            },
          });
          if (removeUserError) {
            console.error('Something went wrong in mutation', removeUserError)
        } else {
            props.onRemove()
        }
    }

    const userCheckAndUpdateError = () => {
        const {success, message} = userCheck()
        if (success) setUserError("")
        else setUserError((message)? message : "")
    }

    if (isUpdated) return  <div className="flex flex-col space-y-2  text-center content-center">
        <div>
            Utente modificato correttamente
        </div>
        <div>
            <Button color="blue" onClick={() => {setIsUpdated(false)}} >Continua</Button>
        </div>
    </div>

    return (
      <div className="flex flex-col space-y-2  text-center">
        <label className="font-bold" >Modifica Utente</label>
        <div className="text-rossoLucin text-sm">{userError}</div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Email
            </div>
            <div>
                {user.email}
            </div>
            {(!user.auth.isEmailVerified)? 
                <div className="text-xs text-orange-500">
                    (Email non verificata)
                </div> : null
            }
        </div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Ultimo Accesso
            </div>
            <div>
                {(user.auth.lastLogin) ? new Date(user.auth.lastLogin).toLocaleString("it-IT") : "Mai"}
            </div>
        </div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Denominazione
            </div>
            <div>
                <TextBox 
                    id="name" 
                    required={true} 
                    type="text" 
                    value={user.basicInfo.name} 
                    onChange={(event) => {
                        const basicInfo = { ...user.basicInfo, name: event.target.value }
                        setUser({...user, basicInfo})}}
                    onBlur={userCheckAndUpdateError}
                />
            </div>
        </div>
        
        {
            (user.auth.isEmailVerified) ? <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4 font-semibold">Stato </Switch.Label>
                    <Switch
                        checked={user.auth.isActive}
                        onChange={(enabled) => {
                            const auth = {...user.auth, isActive: enabled}
                            setUser({...user, auth})
                        }}
                        className={`${
                            user.auth.isActive ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                user.auth.isActive ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group> : <div className="flex flex-row space-x-2">
            <div className="grid content-center font-semibold">
                Stato
            </div>
            <div className="grid content-center w-full">
                <div>
                    {user.auth.isActive ? "Attivo" : "Non Attivo"}
                </div>
                <div className="text-xs text-orange-500">
                    (Verificare email)
                </div>
            </div>
        </div>
        }

        <div className="flex flex-row">
            {(!updateUserLoading && !removeUserLoading)?
                <div className="flex flex-row w-full  space-x-2">
                    <Button disabled={userError!==""} full color="blue" onClick={() => update()}>Modifica</Button>
                    {(user.auth.isActive === false || !user.auth.isEmailVerified)? <Button full color="red" onClick={() => remove()}>Elimina</Button>: null}
                </div>: <div className="flex-1"><Loading /></div>
            }
        </div>
      </div>
    )
  }