
const DB = localStorage;
const appKey = (key: string) => key;

export type TAuthUser = {
  accessToken: string
  refreshToken: string
  user: {
    _id: string
  }
}

export const appStorage = {
    store: DB,
    getObject: (key: string) => {
      let result = null;
      try {
        let item: any = DB.getItem(key);
        result = JSON.parse(item);
      } catch (error) {
        console.error('appStorage getObject ' + appKey(key) + ' | ' + error);
      }
      return result;
    },
    setObject: (key: string, item: Object) => {
      let result = null;
      try {
        result = result = DB.setItem(appKey(key), JSON.stringify(item));
      } catch (error) {
        console.error('appStorage getObject ' + appKey(key) + ' | ' + error);
      }
      return result;
    },
    removeObject: (key: string) => {
      let result = DB.removeItem(appKey(key));
      return result;
    },
  };
  