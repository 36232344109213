import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { ADMIN_ORDERS } from "../../storage/queries/order";
import { Order } from "../../storage/types";
import { Table } from "../../ui/Table";
import { OrderView } from "./OrderView";

const tableColumns : TableColumn<any>[]= [
  {
    name: 'Data',
    // selector: (row: Order) => new Date(row.createdAt).toLocaleString('it-IT'),
    selector: (row: Order) => row.createdAt as any,
    cell: row => new Date(row.createdAt).toLocaleString('it-IT'),
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Utente',
    selector: (row: Order) => (row && row.user && row.user.basicInfo && row.user.basicInfo.name)? row.user.basicInfo.name : 'Sconosciuto',
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Prodotti',
    selector: (row: Order) => row.products.length,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  }
]

interface ProductsViewProps {
    route?: any;
  }
  
export const OrdersView = (props: ProductsViewProps) => {
  
    const { loading, error, data, refetch } = useQuery<{ AdminOrders: Order[] }>(ADMIN_ORDERS, {
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
    });

    const [orders, setOrders] = useState<Order[]>([]) 
    const [selectedOrder, setSelectedOrder] = useState<Order>()

    useEffect(() => {
      if (data?.AdminOrders) {
        setOrders(data.AdminOrders)
      }
      }, [data]);
  
    if (error) return <div>Error: {error}</div>

    const selectOrder = (order: Order) => {
      console.log(selectedOrder)
      console.log(order)
      if (!selectedOrder) {
        setSelectedOrder(order)
      } else {
        if (selectedOrder._id === order._id) {
          setSelectedOrder(undefined)
        } else {
          setSelectedOrder(order)
        }
      }
    }

    // console.log(orders)

    return (
      <div className="flex space-x-2">
        <div className={`container grow-0 ${(selectedOrder? 'w-1/2': 'w-full')} p-2`}>
          <Table 
            columns={tableColumns}
            data={orders}
            height={'calc(100vh - 240px)'}
            title='Ordini'
            loading={loading}
            noDataComponent="Nessun Ordine da Visualizzare"
            defaultSortFieldId='1'
            defaultSortInvert={true}
            onRowClick={selectOrder}
            selectedRowId={selectedOrder?._id}
          />
        </div>
        <div className={`w-1/2`}>
          <OrderView order={selectedOrder} />
        </div>
      </div>
    );
  };
  