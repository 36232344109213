import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { USER_LOGGED } from "../storage/queries/userState";
import { AutoLogin } from "../views/auth/AutoLogin";

import { Login } from "../views/auth/Login";

interface AuthProps {}

const Auth = (props: AuthProps) => {
  console.log('Auth Layout')
  let navigate = useNavigate();
  const { data, loading, error } = useQuery(USER_LOGGED);

  const initApp = async () => {
    console.log('Auth Layout data:', data)
    if (data && data.userLogged) {
      navigate('/admin')
    }
  };

  useEffect(() => {
    initApp();
  });
  if (error) return <div>{error}</div>
  if (loading) return <div>{loading}</div>

  return (
    <>
      <section className="">
        <Routes>
          <Route path="newUser/:email/:code" element={<AutoLogin />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </section>
    </>
  );
};

export { Auth };
