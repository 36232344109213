import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { Bar, BarChart, CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { ADMIN_ORDERS } from "../../storage/queries/order";
import { loadProductsData, loadProductsVariables, LOAD_PRODUCTS } from "../../storage/queries/product";
import { ExternalProduct, Order } from "../../storage/types";
import { Button } from "../../ui";
import { FilePicker } from "../../ui/FilePicker";
import { Table } from "../../ui/Table";

const dataDemo = [
  {
    name: '14/07/2022',
    ordini: 12,
  },
  {
    name: '15/07/2022',
    ordini: 3,
  },
  {
    name: '16/07/2022',
    ordini: 7,
  },
  {
    name: '17/07/2022',
    ordini: 14,
  },
  {
    name: '18/07/2022',
    ordini: 22,
  },
  {
    name: '19/07/2022',
    ordini: 8,
  },
];

interface DashViewProps {
  route?: any;
}

const tableColumns : TableColumn<any>[]= [
  {
    name: 'id',
    selector: (row: ExternalProduct) => row.id,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'nome',
    selector: (row: ExternalProduct) => row.name,
    sortable: true,
    minWidth: '50%',
    style: { fontWeight: 600 },
  },
  {
    name: 'prezzo',
    selector: (row: ExternalProduct) => `€ ${row.price}`,
    sortable: true,
    minWidth: '30%',
    style: { fontWeight: 600 },
  },
]

const ImportProductsPrices = () => {
  const [file, setFile] = useState<File>();
  const [fileContent, setFileContent] = useState<ExternalProduct[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [loadProducts, { error: loadProductsError, loading: loadProductsLoading, data: loadProductsData }] = useMutation<
    loadProductsData,
    loadProductsVariables
  >(LOAD_PRODUCTS);

  useEffect(() => {
    if (loadProductsError) {
      setErrorMessage(loadProductsError.message);
    }
    if (loadProductsData) {
      setFile(undefined)
      setFileContent([])
      setErrorMessage(``)
      setIsSuccess(true);
    }
  }, [loadProductsError, loadProductsData]);

  const handleChangeFile = (importedFile?: File) => {
    if (!importedFile) {
      setFile(undefined);
      setFileContent([])
      return 
    }
    setFile(importedFile)
    var reader = new FileReader();
    reader.onload = function(e) {
        var content = reader.result as string;
        try {
          const parsedContent = JSON.parse(content);
          if (parsedContent && parsedContent.products) {
            setFileContent(parsedContent.products)
          } else {
            setErrorMessage(`Campo products non trovato nel file`)
          }
        } catch (error) {
          setErrorMessage(`Errore in conversione file: ${error}`)
        }
    }
    reader.readAsText(importedFile);    
  }

  const importFile = () => {
    console.log("importFile")
    loadProducts({ variables: { products: fileContent } })
  }

  return <div className="grid grid-cols-1 justify-items-center content-start space-y-2 h-full">
    <div className="text-rossoLucin text-center text-2xl">
        Aggiornamento prezzi prodotti
    </div>
    {(loadProductsLoading)? <div className="text-center text-2xl">
      Caricamento in corso...
      </div> : <>
      <div>
        <div> {
          fileContent && fileContent.length > 0 ?
            <Table 
                  columns={tableColumns}
                  data={fileContent}
                  height={'calc(100vh - 240px)'}
                  title='Prodotti'
                  loading={false}
                  filterSelector={'name'}
                  selectableRows={false}
                  pointerOnHover={false}
                /> : <div>
                      <div className="text-center font-light">
                        Da qui puoi importare i prezzi dei prodotti attraverso il file json creato dal gestionale
                      </div>
                      <FilePicker file={file} onFileChanged={handleChangeFile}/>
                    </div> 
          }
        </div>
      </div>
      <div className="text-red-600">
        {errorMessage}
      </div>
      <div>
        {isSuccess && <div className="text-center text-2xl">
            {loadProductsData?.AdminLoadProducts.length} Prodotti aggiornati 
            <div>
              {loadProductsData?.AdminLoadProducts.map(
                  (product, index) => <div key={product._id}>{`${product.name} nuovo prezzo: ${product.price} €`}</div>
                  )}
            </div>
          </div>
          }
      </div>
      <div className="w-full px-4 flex space-x-2">
        {
          fileContent && fileContent.length > 0 ?
          <>
            <Button full color="red" onClick={() => {handleChangeFile()}}>Annulla</Button> 
            <Button full color="purple" onClick={importFile}>Importa</Button> 
          </>: null
        }
      </div>
    </>
    }
    </div>

}

const OrdersStats = () => {

  const { loading, error, data, refetch } = useQuery<{ AdminOrders: Order[] }>(ADMIN_ORDERS, {
    fetchPolicy: 'cache-and-network',
    pollInterval: 5000,
  });
  const [orders, setOrders] = useState<Order[]>() 
  useEffect(() => {
    if (data?.AdminOrders) {
      setOrders(data.AdminOrders)
    }
    }, [data]);

  if (!orders) return <div>
    Caricamento dati...
  </div>

  const groupBy = (array: Order[]) => {
    // Return the reduced array
    const result = []
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      const currentItemDayDate = moment(element.createdAt).format('DD/MM/YYYY');
      const foundIndex: number = result.findIndex((item: any) => item.date === currentItemDayDate);
      if (foundIndex > -1) {
        result[foundIndex].ordini++
      } else {
        result.push({
          date: currentItemDayDate,
          ordini: 1
        })
      }
    }
    result.length = result.length > 7 ? 7 : result.length
    return result.reverse()
  };

  console.log(groupBy(orders))

  return <div className="grid content-start justify-items-center">
    <div className="text-2xl text-cente">Storia Ordini</div>
    <div className="pt-4">
      <BarChart
            width={500}
            height={300}
            data={groupBy(orders)}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="ordini" fill="#8884d8" />
      </BarChart>    
    </div>
  </div>
}

const DashView = (props: DashViewProps) => {

  return (
    <div className="grid grid-cols-3 h-full w-full">
        <ImportProductsPrices />
        <OrdersStats />
    </div>
  );
};

export { DashView };
