const ENV = process.env.NODE_ENV;

interface EnvVars {
  mode: 'development' | 'production' 
  auth: {
    url: string;
  };
  backend: {
    url: string;
  };
}

const CONFIG: EnvVars = {
  mode: 'production',
  auth: {
    url: 'https://auth.lucin.onubo.com',
  },
  backend: {
    url: 'https://backend.lucin.onubo.com/graphql',
  },
};

export { CONFIG };
