import { useState } from "react"
import { User, UserCreateInput } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useMutation } from "@apollo/client"
import { createUserData, createUserVariables, CREATE_USER } from "../../storage/queries/user"
import { ImagePicker } from "../../ui/ImagePicker"
import { checkEmail } from "../../libs/toolkit"

interface UserCardProps {
    onClose: () => void
    onCreate: () => void
    users: User[]
  }

export const CreateUserCard = (props: UserCardProps) => {
    const [createUser, { error: createUserError, loading: createUserLoading }] = useMutation<
        createUserData,
        createUserVariables
    >(CREATE_USER);

    const userInit : UserCreateInput = {
        basicInfo: {
            name: '',
        },
        email: ''
    }

    const [user, setUser]=useState<UserCreateInput>(userInit)
    const [userError, setUserError] = useState('')

    const update = (editedUser: UserCreateInput) => {
        setUser(editedUser)
    }

    const updateUserError = () => {
        const {success, message} = userCheck()
        if (!success) {
            if (!message) {
                setUserError('Errore generico')
            } else {
                setUserError(message)
            }
        } else {
            if (userError !== "") {
                setUserError("")
            }
        }
    }

    const userCheck = (): {success: boolean, message?: string} => {
        if (!user.basicInfo || !user.basicInfo.name || user.basicInfo.name === "") return {success: false, message: 'Denominazione utente errato'}
        if (props.users.findIndex((usr) => usr.basicInfo.name.toLocaleLowerCase() === user.basicInfo.name.toLocaleLowerCase()) !== -1) {
            return {success: false, message: 'Denominazione utente già presente'}
        }
        if (props.users.findIndex((usr) => usr.email.toLocaleLowerCase() === user.email.toLocaleLowerCase()) !== -1) {
            return {success: false, message: 'Email utente già presente'}
        }
        if (!checkEmail(user.email)) {
            return {success: false, message: 'Email non valida'}
        }
        return {success: true}
    }

    const newUser = async () => {
        const {success, message} = userCheck()
        if (success) {
            const res = await createUser({
                variables: {
                    user
                },
              });
            if (createUserError) {
                console.error('Something went wrong in mutation', createUserError)
            }
            if (res.errors) {
                console.log('Something went wrong')
                setUserError('Utente non creato')
            } else {
                // console.log('User succesfully inserted', user)
                props.onCreate()
                setUser(userInit)
            } 

        } else {
            if (message) setUserError(message)
                else setUserError('Errore generico')
        }
    }

    return (
      <div className="flex flex-col space-y-2 text-center">
        <label className="font-bold">Crea nuovo Utente</label>
        <div className="text-rossoLucin text-sm">{userError}</div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Denominazione
            </div>
            <div>
                <TextBox 
                    id="name" 
                    required={true} 
                    placeholder="Denominazione utente..."
                    type="text" 
                    value={user.basicInfo.name} 
                    onChange={(event) => {
                        const basicInfo = { ...user.basicInfo, name: event.target.value }
                        setUser({...user, basicInfo})
                    }}
                    onBlur={(event) => {
                        const basicInfo = { ...user.basicInfo, name: event.target.value }
                        update({...user, basicInfo});
                        updateUserError()
                    }}
                />
            </div>
        </div>
        <div className="flex flex-col">
            <div className="font-semibold">
                Email
            </div>
            <div>
                <TextBox 
                    id="email" 
                    required={true} 
                    placeholder="Email utente..."
                    type="text" 
                    value={user.email} 
                    onChange={(event) => {
                        setUser({...user, email: event.target.value})
                        updateUserError()
                    }}
                    onBlur={(event) => {
                        update({...user, email: event.target.value});
                        updateUserError()
                    }}
                />
            </div>
        </div>
        <div className="flex flex-row space-x-2">
            {(!createUserLoading)? <>
            <Button disabled={!userCheck().success} full color="blue" onClick={newUser}>Crea</Button>
            <Button full color="blue" onClick={() => {setUser(userInit); props.onClose()}}>Annulla</Button></>: 
             <div className="flex-1"><Loading /></div>}
        </div>
      </div>
    )
  }