export enum ProductTypes {
    vegetables = "vegetables",
    fruits = "fruits",
    dairyProducts = "dairyProducts"
}

export enum ProductOrderTypes {
    kg = "kg",
    piece = "piece",
    box = "box",
    vs = "vs"
}


export class Image {
    name?: string;
    url?: string;
}

export class User {
    "_id": string;
    "email": string;
    "auth": {
        "createdAt": Date
        "isActive": boolean
        "isEmailVerified": boolean
        "lastLogin": Date
    }
    "basicInfo": {
        "name": string
    }
}

export class Product {
    "_id": string;
    "name": string;
    "price": number;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "orderType"?: ProductOrderTypes
    "priceType"?: ProductOrderTypes
    "externalId"?: string;
    "deliveryTime"?: number;
    "image"?: Image;
}

export class ProductInput {
    "_id": string;
    "name": string;
    "price": number;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "orderType"?: ProductOrderTypes
    "priceType"?: ProductOrderTypes
    "externalId"?: string;
    "deliveryTime"?: number;
}

export class UserInput {
    "basicInfo": {
        "name": string
    }
}

export class UserEditInput {
    "basicInfo": {
        "name": string
    }
    "isActive": boolean
}

export class UserCreateInput {
    "email": string
    "basicInfo": {
        "name": string
    }
}

export class ProductCreateInput {
    "name": string;
    "price": number;
    "type": ProductTypes;
    "ifSponsored": boolean;
    "ifVisible": boolean;
    "orderType"?: ProductOrderTypes
    "priceType"?: ProductOrderTypes
    "externalId"?: string;
    "deliveryTime"?: number;
}

export class UserLimitedInfo {
    "_id": string;
    "email": string;
    "basicInfo": {
        "name": string
    }
}

export class Order {
    "_id": string;
    "user"? : UserLimitedInfo
    "createdAt": Date;
    "info": string;
    "products" : {
        "product": {
            "_id": string
            "name": string
            "type": ProductTypes
            "orderType": ProductOrderTypes
            "priceType": ProductOrderTypes
        }
        "quantity": number
    }[]
}

enum externalProductSKU {
    KG = "KG",
    NR = "NR"
}
  

export class ExternalProduct {
    "id": string
    "name": string
    "type": string
    "price": number
    "sku": externalProductSKU
  }
  
  