import { useCallback, useEffect, useState } from "react"
import { Product, ProductInput } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useMutation, useQuery } from "@apollo/client"
import { ADMIN_PRODUCT, removeProductData, removeProductVariables, REMOVE_PRODUCT, upadeteProductImageData, upadeteProductImageVariables, updateProductData, updateProductVariables, UPDATE_IMAGE, UPDATE_PRODUCT } from "../../storage/queries/product"
import { ImagePicker } from "../../ui/ImagePicker"

interface ProductCardProps {
    productId: string;
    products: Product[]
    onRemove: () => void
  }

export const EditProductCard = (props: ProductCardProps) => {
    const { loading, error, data } = useQuery<{ AdminProduct: Product }>(ADMIN_PRODUCT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            productId: props.productId,
        },
    });
    
    const [removeProduct, { error: removeProductError, loading: removeProductLoading }] = useMutation<
        removeProductData,
        removeProductVariables
    >(REMOVE_PRODUCT);


    const [updateProduct, { error: updateProductError, loading: updateProductLoading }] = useMutation<
        updateProductData,
        updateProductVariables
    >(UPDATE_PRODUCT);

    const [product, setProduct]=useState(data?.AdminProduct)
    const [isUpdated, setIsUpdated] = useState(false)
    const [image, setImage] = useState<File>()
    const [productError, setProductError] = useState('')

    useEffect(() => {
    if (data?.AdminProduct) {
        setProduct(data.AdminProduct)
    }
    }, [data]);

    if (loading) return <div>loading</div>
    if (error) return <div>Error on query: {error}</div>
    if (!product) return <div>Something went wrong</div>

    const selectItems = []
    selectItems.push({label: 'Verdure', value: 'vegetables'})
    selectItems.push({label: 'Frutta', value: 'fruits'})
    selectItems.push({label: 'Affettati e Latticini', value: 'dairyProducts'})

    const orderTypeItems = []
    orderTypeItems.push({label: 'Vendita al Kg', value: 'kg'})
    orderTypeItems.push({label: 'Vendita al pezzo', value: 'piece'})
    orderTypeItems.push({label: 'Vendita alla cassetta', value: 'box'})
    orderTypeItems.push({label: 'Vendita alla vaschetta', value: 'vs'})

    const priceTypeItems = []
    priceTypeItems.push({label: 'Prezzo al Kg', value: 'kg'})
    priceTypeItems.push({label: 'Prezzo al pezzo', value: 'piece'})
    priceTypeItems.push({label: 'Prezzo alla cassetta', value: 'box'})
    priceTypeItems.push({label: 'Prezzo alla vaschetta', value: 'vs'})

    const productCheck = (): {success: boolean, message?: string} => {
        if (!product.name || product.name === "") return {success: false, message: 'Nome prodotto errato'}
        if (props.products.findIndex((prod) => {
            if (prod._id === props.productId) return false
            return prod.name.toLocaleLowerCase() === product.name.toLocaleLowerCase()
        }) !== -1) {
            return {success: false, message: 'Prodotto già presente'}
        }
        if (!product.type) return {success: false, message: 'Tipo prodotto errato'}
        if (isNaN(product.price)) return {success: false, message: 'Prezzo prodotto errato'}
        return {success: true}
    }

    const productCheckAndUpdateError = () => {
        const {success, message} = productCheck()
        if (success) setProductError("")
        else setProductError((message)? message : "")
    }

    const update = async () => {
        const {success, message} = productCheck()
        if (success) {
            const tmpProduct : Product = product
            setProductError('')
            const res = await updateProduct({
                variables: {
                  product: {
                        _id: tmpProduct._id,
                        name: tmpProduct.name,
                        price: tmpProduct.price,
                        type: tmpProduct.type,
                        ifSponsored: tmpProduct.ifSponsored,
                        ifVisible: tmpProduct.ifVisible,
                        orderType: tmpProduct.orderType,
                        priceType: tmpProduct.priceType,
                        externalId: tmpProduct.externalId,
                  },
                  image: image
                },
              });
              if (res.errors) {
                  console.log('Something went wrong')
                  setProductError('Prodotto non aggiornato')
              } else {
                setIsUpdated(true)
              }
        } else {
            if (message) setProductError(message)
                else setProductError('Errore generico')
        }
    }

    const remove = async () => {
        await removeProduct({
            variables: {
                productId: props.productId,
            },
          });
          if (removeProductError) {
            console.error('Something went wrong in mutation', removeProductError)
        } else {
            // console.log('Product succesfully inserted', product)
            props.onRemove()
        }
    }

    const imageChanged = async (image: File) => {
        setImage(image)
    }

    const changePrice = (price: string) => {
        productCheckAndUpdateError()
        // if (!price || price === "") {
        //     setProductError('Prezzo prodotto non conforme')
        // } else {
        //     setProductError('')
        // }
    }

    if (isUpdated) return  <div className="flex flex-col space-y-2  text-center content-center">
        <div>
            Prodotto modificato correttamente
        </div>
        <div>
            <Button color="blue" onClick={() => {setIsUpdated(false)}} >Continua</Button>
        </div>
    </div>

    return (
      <div className="flex flex-col space-y-2  text-center">
        <label className="font-bold">Modifica Prodotto</label>
        <div className="text-rossoLucin text-sm">{productError}</div>
        <TextBox 
            id="name" 
            required={true} 
            type="text" 
            value={product.name} 
            onChange={(event) => setProduct({...product, name: event.target.value.toUpperCase()})}
            onBlur={productCheckAndUpdateError}
        />
        <TextBox 
            id="externalId" 
            required={true} 
            placeholder="Codice gestionale..."
            type="text" 
            value={product.externalId} 
            onChange={(event) => {setProduct({...product, externalId: event.target.value.toUpperCase()})}}
        />
        <Select 
            zIndex={999} 
            defaultValue={product.type} 
            items={selectItems} placeholder='Tipo di prodotto' 
            onChange={(value) => setProduct({...product, type: value.value})}
            />
        <Select 
            zIndex={998} 
            defaultValue={product.orderType} 
            items={orderTypeItems} placeholder='Tipo di vendita' 
            onChange={(value) => setProduct({...product, orderType: value.value})}
            />
        <Select 
            zIndex={997} 
            defaultValue={product.priceType} 
            items={priceTypeItems} placeholder='Tipo di prezzo' 
            onChange={(value) => setProduct({...product, priceType: value.value})}
            />
        <TextBox 
            id="price" 
            step="0.1" 
            required={true} 
            type="number" 
            value={product.price} 
            onChange={(event) => {
                setProduct({...product, price: parseFloat(event.target.value)});
            }}
            onBlur={(event) => changePrice(event.target.value)}
        />
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Visibile </Switch.Label>
                    <Switch
                        checked={product.ifVisible}
                        onChange={(enabled) => {setProduct({...product, ifVisible : enabled})}}
                        className={`${
                            product.ifVisible ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifVisible ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Vetrina </Switch.Label>
                    <Switch
                        checked={product.ifSponsored}
                        onChange={(enabled) => {setProduct({...product, ifSponsored : enabled})}}
                        className={`${
                            product.ifSponsored ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifSponsored ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <ImagePicker onImageChanged={imageChanged} image={image} imageURL={product.image?.url}/>

        <div className="flex flex-row space-x-2">
            {(!updateProductLoading && !removeProductLoading)?
                <div className="flex flex-row w-full">
                    <Button disabled={productError!==""} full color="blue" onClick={() => update()}>Modifica</Button>
                    <Button full color="red" onClick={() => remove()}>Elimina</Button>
                </div>: <div className="flex-1"><Loading /></div>
            }
        </div>
      </div>
    )
  }