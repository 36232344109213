import { ProductOrderTypes, ProductTypes } from "../storage/types"

export const checkEmail = (email: string) : boolean => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))

export const convertProductType = (type: ProductTypes) => {
    switch (type) {
      case ProductTypes.dairyProducts:
        return 'Salumi e Formaggi'
      case ProductTypes.fruits:
        return 'Frutta'
      case ProductTypes.vegetables:
        return 'Verdura'
      default:
        return type
    }
  }
  
export const translateOrderType = (orderType?: ProductOrderTypes) => {
    switch (orderType) {
        case ProductOrderTypes.box:
            return 'Cassetta'
        case ProductOrderTypes.piece:
            return 'Pezzo'
        case ProductOrderTypes.kg:
            return 'Kg'
        case ProductOrderTypes.vs:
            return 'Vaschetta'
        default:
            return orderType
    }

}