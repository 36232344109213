import React, { Fragment, useState } from 'react';
import { useQuery } from '@apollo/client';
import { User, USER_ME } from '../../storage/queries/user';
import { Button } from '../../ui';
import { logout } from '../../libs/auth';

// import { logout } from '../../libs/apollo/auth';

const UserMenu = () => {
  const { data, loading, error } = useQuery<{
    UserMe: User;
  }>(USER_ME, {
    fetchPolicy: 'network-only',
    //skip: !auth.user,
  });

  if (loading) {
    return (<>Loading</>)
  }

  if (error) {
    return (<>{error}</>)
  }

  return (
    <>
    <Button onClick={() => {logout()}}>
      Logout
    </Button>
    </>
  );
};

export { UserMenu };
