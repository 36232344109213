import { useEffect, useState } from "react"
import { Product, ProductCreateInput, ProductInput, ProductTypes } from "../../storage/types"
import { Button, Loading, TextBox } from "../../ui"
import { Select } from "../../ui/Select"
import { Switch } from '@headlessui/react'
import { useMutation, useQuery } from "@apollo/client"
import { ADMIN_PRODUCTS, createProductData, createProductVariables, CREATE_PRODUCT } from "../../storage/queries/product"
import { ImagePicker } from "../../ui/ImagePicker"

interface ProductCardProps {
    onClose: () => void
    onCreate: () => void
    products: Product[]
  }

export const CreateProductCard = (props: ProductCardProps) => {
    const [createProduct, { error: createProductError, loading: createProductLoading }] = useMutation<
        createProductData,
        createProductVariables
    >(CREATE_PRODUCT);

    const productInit : ProductCreateInput = {
        name: '',
        price: 0,
        type: ProductTypes.fruits,
        ifVisible: true,
        ifSponsored: false,
    }

    const [product, setProduct]=useState<ProductCreateInput>(productInit)
    const [productError, setProductError] = useState('')
    const [image, setImage] = useState<File>()

    const update = (editedProduct: ProductCreateInput) => {
        setProduct(editedProduct)
    }

    const updateProductError = () => {
        const {success, message} = productCheck()
        if (!success) {
            if (!message) {
                setProductError('Errore generico')
            } else {
                setProductError(message)
            }
        } else {
            if (productError !== "") {
                setProductError("")
            }
        }
    }

    const productCheck = (): {success: boolean, message?: string} => {
        if (!product.name || product.name === "") return {success: false, message: 'Nome prodotto errato'}
        if (props.products.findIndex((prod) => prod.name.toLocaleLowerCase() === product.name.toLocaleLowerCase()) !== -1) {
            return {success: false, message: 'Prodotto già presente'}
        }
        if (!product.type) return {success: false, message: 'Tipo prodotto errato'}
        if (isNaN(product.price)) return {success: false, message: 'Prezzo prodotto errato'}
        return {success: true}
    }

    const newProduct = async () => {
        const {success, message} = productCheck()
        if (success) {
            const res = await createProduct({
                variables: {
                  product,
                  image: image
                },
              });
            if (createProductError) {
                console.error('Something went wrong in mutation', createProductError)
            }
            if (res.errors) {
                console.log('Something went wrong')
                setProductError('Prodotto non creato')
            } else {
                // console.log('Product succesfully inserted', product)
                props.onCreate()
                setImage(undefined)
                setProduct(productInit)
            } 

        } else {
            if (message) setProductError(message)
                else setProductError('Errore generico')
        }
    }

    const selectItems = []
    selectItems.push({label: 'Verdure', value: 'vegetables'})
    selectItems.push({label: 'Frutta', value: 'fruits'})
    selectItems.push({label: 'Affettati e Latticini', value: 'dairyProducts'})

    const orderTypeItems = []
    orderTypeItems.push({label: 'Vendita al Kg', value: 'kg'})
    orderTypeItems.push({label: 'Vendita al pezzo', value: 'piece'})
    orderTypeItems.push({label: 'Vendita alla cassetta', value: 'box'})
    orderTypeItems.push({label: 'Vendita alla vaschetta', value: 'vs'})

    const priceTypeItems = []
    priceTypeItems.push({label: 'Prezzo al Kg', value: 'kg'})
    priceTypeItems.push({label: 'Prezzo al pezzo', value: 'piece'})
    priceTypeItems.push({label: 'Prezzo alla cassetta', value: 'box'})
    priceTypeItems.push({label: 'Prezzo alla vaschetta', value: 'vs'})

    const imageChanged = (image: File) => {
        setImage(image)
    }

    return (
      <div className="flex flex-col space-y-2 text-center">
        <label className="font-bold">Crea nuovo Prodotto</label>
        <div className="text-rossoLucin text-sm">{productError}</div>
        <TextBox 
            id="name" 
            required={true} 
            placeholder="Nome prodotto..."
            type="text" 
            value={product.name} 
            onChange={(event) => {setProduct({...product, name: event.target.value.toUpperCase()})}}
            onBlur={(event) => {update({...product, name: event.target.value}); updateProductError()}}
        />
        <TextBox 
            id="externalId" 
            required={true} 
            placeholder="Codice gestionale..."
            type="text" 
            value={product.externalId} 
            onChange={(event) => {setProduct({...product, externalId: event.target.value.toUpperCase()})}}
            onBlur={(event) => {update({...product, externalId: event.target.value})}}
        />
        <Select 
            zIndex={999} 
            defaultValue={product.type} 
            items={selectItems} placeholder='Tipo di prodotto' 
            onChange={(value) => update({...product, type: value.value})}
            />
        <Select 
            zIndex={998} 
            defaultValue={product.orderType} 
            items={orderTypeItems} placeholder='Tipo di vendita' 
            onChange={(value) => update({...product, orderType: value.value, priceType: value.value})}
            />
        <Select 
            zIndex={997} 
            defaultValue={product.priceType} 
            items={priceTypeItems} placeholder='Tipo di prezzo' 
            onChange={(value) => update({...product, priceType: value.value})}
            />
        <TextBox 
            id="price" 
            step="0.1" 
            required={true} 
            type="number" 
            value={product.price} 
            onChange={(event) => {
                setProduct({...product, price: parseFloat(event.target.value)});
                updateProductError()
            }}
            />
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Visibile </Switch.Label>
                    <Switch
                        checked={product.ifVisible}
                        onChange={(enabled) => {update({...product, ifVisible : enabled})}}
                        className={`${
                            product.ifVisible ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifVisible ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <Switch.Group>
            <div className="flex items-center space-x-2">
                    <Switch.Label className="mr-4">Vetrina </Switch.Label>
                    <Switch
                        checked={product.ifSponsored}
                        onChange={(enabled) => {update({...product, ifSponsored : enabled})}}
                        className={`${
                            product.ifSponsored ? 'bg-blue-600' : 'bg-gray-200'
                        } relative inline-flex items-center h-6 rounded-full w-11`}
                        >
                        <span
                            className={`${
                                product.ifSponsored ? 'translate-x-6' : 'translate-x-1'
                            } inline-block w-4 h-4 transform bg-white rounded-full`}
                        />
                    </Switch>
            </div>
        </Switch.Group>
        <ImagePicker onImageChanged={imageChanged} image={image}/>
        <div className="flex flex-row space-x-2">
            {(!createProductLoading)? <>
            <Button disabled={!productCheck().success} full color="blue" onClick={newProduct}>Crea</Button>
            <Button full color="blue" onClick={() => {setProduct(productInit); props.onClose()}}>Annulla</Button></>: 
             <div className="flex-1"><Loading /></div>}
        </div>
      </div>
    )
  }