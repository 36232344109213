import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginEmail, requestCode } from "../../libs/auth";
import { Button, Loading, TextBox } from "../../ui";

const Login = () => {
  let navigate = useNavigate();

  const [email, setEmail] = useState<string>("")
  const [code, setCode] = useState<string>("")

  const [loginError, setLoginError] = useState<null | string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCodeSent, setIsCodeSent] = useState<{success: boolean, error: string}>({success: false, error: ""})

  const sendCode = () => {
    setIsLoading(true)
    setLoginError(null)
    requestCode(email).then((res) => {
      console.log(res)
      if (res.success === true) {
        console.log('Code sent')
        setIsCodeSent({success: true, error: ""})
      } else {
        console.log('Something went wrong')
        setLoginError('Codice non inviato, riprovare')
        setIsCodeSent({success: false, error: "Codice non inviato, riprovare"})
      }
      setIsLoading(false)
    })
  }

  const login = () => {
    setIsLoading(true);
    setLoginError(null);
    loginEmail(email, code).then((res) => {
      if (res.success) {
        console.log('succesfully logged in')
        return navigate('/admin')
      } else {
        console.log('error in login',res.error)
        if (res.error === "wrong or expired code") {
          setLoginError("Codice errato o scaduto")
        } else {
          setLoginError("Errore imprevisto, riprovare o contattare Pandoper")
        }
        setIsLoading(false);
      }
    })
  };

  const handleButton = () => {
    if (isCodeSent.success === true) {
      login()
    } else {
      sendCode()
    }
  }

  return (
    <>
      <div className="grid place-items-center h-screen">
        <div className="">
          <img className="max-h-64" src="/images/icon-512.png" alt="logo"/>
          <div>
            <form>
              <div className="relative w-full mb-3">
                <TextBox
                  id="email"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  required={true}
                  onChange={(event) => { setEmail(event.target.value); if (event.target.value === "") setIsCodeSent({success: false, error: ""})}}
                />
              </div>
              <div className={isCodeSent.success === false? "relative w-full mb-3 hidden" : "relative w-full mb-3"}>
                <div className="text-sm text-green-500">Ti abbiamo inviato una email con il codice per autenticarti</div>
                <TextBox
                  id="code"
                  type="text"
                  label="Codice"
                  placeholder="Codice"
                  required={true}
                  onChange={(event) => { setCode(event.target.value) }}
                />
              </div>

              <div className="text-center mt-6">
                <div>
                  {(loginError && isCodeSent.success === true) ? <div className="text-sm text-red-500">{loginError}</div> : null}
                </div>

                <Button
                  size="md"
                  full
                  color="blue"
                  onClick={handleButton}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="w-full flex items-center justify-center">
                      <Loading color={"#fff"} height={20} width={20} />
                    </div>
                  ) : isCodeSent.success === true ? (
                    "Accedi"
                  ): 'Invia Codice'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export { Login };
