import React, { MouseEvent, ReactNode, useMemo } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { useFormFields } from '../hooks/forms';
import { TextBox } from './TextBox';
import { Loading } from './Loading';
import { Product } from '../storage/types';

interface TableProps {
  columns: TableColumn<any>[];
  data: any[];
  height: string;
  title: string;
  loading: boolean;
  onRowClick?: (row: any, e: any) => void | undefined;
  addElementComponent?: ReactNode;
  selectableRows?: boolean;
  filterSelector?: string;
  defaultSortFieldId?: string;
  defaultSortInvert?: boolean;
  selectedRowId? : string
  noDataComponent? :string
  pointerOnHover?: boolean
}

const Table = (props: TableProps) => {
  const [inputs, handleInputChange] = useFormFields<{
    filter: string;
  }>({
    filter: '',
  });

  const filteredItems = props.filterSelector
    ? props.data?.filter(
        item =>
          item[props.filterSelector as any] &&
          item[props.filterSelector as any].toLowerCase().includes(inputs.filter.toLowerCase())
      )
    : props.data;

    const conditionalRowStyles = [];

    if (props.selectedRowId) { 
      conditionalRowStyles.push({
        when: (row : Product) => row._id === props.selectedRowId,
        style: {
          backgroundColor: '#ef9800',
          color: 'white',
        },
      },)
    }

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="flex flex-row justify-between items-center w-full py-2">
        <h3 className={'font-semibold text-2xl text-gray-600'}>{props.title}</h3>
        <div className="flex flex-row justify-end space-x-2">
          {props.addElementComponent ? props.addElementComponent : null}

          <div className={'flex flex-row'} style={{ width: '200px' }}>
            {props.filterSelector && (
              <TextBox
                id="filter"
                type="text"
                placeholder={'Cerca per nome ...'}
                required={true}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.filter]);

  return (
    <DataTable
      noHeader
      subHeader
      subHeaderComponent={subHeaderComponentMemo}
      fixedHeader
      highlightOnHover
      striped={true}
      fixedHeaderScrollHeight={props.height}
      title={props.title}
      responsive={true}
      columns={props.columns}
      data={filteredItems || []}
      style={styles.container}
      customStyles={styles.table}
      pointerOnHover={(props.pointerOnHover !== undefined || props.pointerOnHover === false) ? false : true}
      conditionalRowStyles={conditionalRowStyles}
      progressPending={props.loading}
      selectableRows={props.selectableRows}
      defaultSortFieldId={props.defaultSortFieldId}
      defaultSortAsc={props.defaultSortInvert ? false : true}
      noDataComponent={
        <div className="w-full flex items-center justify-center py-8 font-bold text-gray-400">
          {(props.noDataComponent)? props.noDataComponent : 'Nessun dato da visualizzare'}
        </div>
      }
      progressComponent={
        <div className="w-full flex items-center justify-center py-8">
          <Loading color={'#666'} />
        </div>
      }
      pagination
      onRowClicked={props.onRowClick}
    />
  );
};

const styles = {
  container: { borderTopLeftRadius: 0, borderTopRightRadius: 0 },
  table: {
    subHeader: {
      style: {
        borderRadius: 30,
      },
    },
    headRow: {
      style: {
        textTransform: 'uppercase',
        backgroundColor: '#e9e9e9',
        borderBottomWidth: '0px',
        borderBottomColor: '#eee',
        borderBottomStyle: 'solid',
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 600,
        color: '#8f8f8f',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    rows: {
      style: {
        '&:not(:last-of-type)': {
          borderBottomStyle: 'solid',
          borderBottomWidth: '0px',
          borderBottomColor: '#eee',
        },
      },
    },
    cells: {
      style: {
        color: '#666',
      },
    },
    table: {
      style: {
        overflow: 'hidden',
        borderBottomLeftRadius: '1rem',
        borderBottomRightRadius: '1rem',
      },
    },
    tableWrapper: {
      style: {
        display: 'table',
      },
    },
  },
};

export { Table };
