import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { CreateProductCard } from "../../components/Product/CreateProduct";
import { EditProductCard } from "../../components/Product/EditProduct";
import { convertProductType, translateOrderType } from "../../libs/toolkit";
import { ADMIN_PRODUCTS, updateProductData, updateProductVariables, UPDATE_PRODUCT } from "../../storage/queries/product";
import { Product, ProductTypes } from "../../storage/types";
import { Button, TextBox } from "../../ui";
import { Select } from "../../ui/Select";
import { Table } from "../../ui/Table";


const tableColumns : TableColumn<any>[]= [
  {
    name: 'nome',
    selector: (row: Product) => row.name,
    sortable: true,
    minWidth: '20%',
    style: { fontWeight: 600 },
  },
  {
    name: 'tipo',
    selector: (row: Product) => convertProductType(row.type),
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
  {
    name: 'codice gestionale',
    selector: (row: Product) => (row.externalId??''),
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
  {
    name: 'prezzo',
    selector: (row: Product) => `${row.price}/${translateOrderType(row.priceType)}`,
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
  {
    name: 'vendita',
    selector: (row: Product) => `${translateOrderType(row.orderType)}`,
    sortable: true,
    minWidth: '12%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Se visibile',
    selector: (row: Product) => (row.ifVisible)? 'Visibile' : 'Nascosto',
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Se vetrina',
    selector: (row: Product) => (row.ifSponsored)? 'In vetrina' : 'Non in vetrina',
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
  {
    name: 'Se foto',
    selector: (row: Product) => (row.image?.url)? 'Si' : 'No',
    sortable: true,
    minWidth: '10%',
    style: { fontWeight: 600 },
  },
]

interface ProductsViewProps {
    route?: any;
  }
  
  const ProductsView = (props: ProductsViewProps) => {
  
    const { loading, error, data, refetch } = useQuery<{ AdminProducts: Product[] }>(ADMIN_PRODUCTS, {
      fetchPolicy: 'cache-and-network',
      pollInterval: 5000,
    });

    const [products, setProducts] = useState<Product[]>([]) 
    const [ifCreateProduct, setIfCreteProduct] = useState<boolean>(false) 
    
    useEffect(() => {
      if (data?.AdminProducts) {
        setProducts(data.AdminProducts)
      }
      }, [data]);
  

    const [selectedProduct, setSelectedProduct] = useState<Product>();

    if (error) return <div>Error: {error}</div>

    const rowSelected = (row: Product) => {
      if (row._id !== selectedProduct?._id) {
        setIfCreteProduct(false)
        setSelectedProduct(row)
      } else {
        setIfCreteProduct(false)
        setSelectedProduct(undefined)
      }
    }

    const onRemoveProduct = () => {
      setSelectedProduct(undefined)
      refetch()
    }

    const createProductButton = <Button color="blue" onClick={() => {setSelectedProduct(undefined); setIfCreteProduct(true)}}>Aggiungi prodotto</Button>

    return (
      <div className="flex space-x-2">
        <div className="container grow-0 w-full p-2">
          <Table 
            columns={tableColumns}
            data={products}
            height={'calc(100vh - 240px)'}
            title='Prodotti'
            loading={loading}
            filterSelector={'name'}
            addElementComponent={createProductButton}
            onRowClick={(row : Product) => {rowSelected(row)}}
            selectedRowId={selectedProduct?._id}
          />
        </div>
        <div className="container w-1/4 p-2 border-2 rounded-md border-gialloLucin">
          {(ifCreateProduct)? 
              <div className="">
                <CreateProductCard products={products} onCreate={refetch} onClose={() => {setIfCreteProduct(false)}}/>
              </div>:
              (selectedProduct)?
              <EditProductCard onRemove={onRemoveProduct} products={products} key={selectedProduct._id} productId={selectedProduct._id}/>
              :null
          }
        </div>
      </div>
    );
  };
  
  export { ProductsView };
  