import { gql } from '@apollo/client';
import { ExternalProduct, Product, ProductCreateInput, ProductInput } from '../types';

export const ADMIN_PRODUCTS = gql`
  query ADMIN_PRODUCTS {
    AdminProducts {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      externalId
      image {
          url
          name
      }
    }
  }
`;

export const ADMIN_PRODUCT = gql`
  query ADMIN_PRODUCT($productId: ID!) {
    AdminProduct(_id: $productId) {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      externalId
      image {
          url
          name
      }
    }
  }
`;

export const REMOVE_PRODUCT = gql`
  mutation ADMIN_PRODUCT($productId: ID!) {
    AdminProductRemove(_id: $productId)
  }
`;


export const UPDATE_PRODUCT = gql`
  mutation UPDATE_PRODUCT($product: ProductInput!, $image: Upload) {
    AdminProductUpdate(product: $product, image: $image) {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      externalId
      image {
          url
          name
      }
    }
  }
`;

export const CREATE_PRODUCT = gql`
  mutation CREATE_PRODUCT($product: ProductCreateInput!, $image: Upload) {
    AdminProductCreate(product: $product, image: $image) {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      externalId
      image {
          url
          name
      }
    }
  }
`;

export const UPDATE_IMAGE = gql`
  mutation UPDATE_IMAGE($_id: string, $image: File!) {
    AdminProductImageUpdate(_id: $_id, image: $image)
  }
`;

export const LOAD_PRODUCTS = gql`
  mutation LOAD_PRODUCTS($products: [ExternalProductInput]) {
    AdminLoadProducts(products: $products) {
      _id
      name
      price
      type
      ifSponsored
      ifVisible
      orderType
      priceType
      deliveryTime
      externalId
      image {
          url
          name
      }
    }
  }
`;


export interface loadProductsVariables {
  products: ExternalProduct[]
}

export interface loadProductsData {
  AdminLoadProducts: Product[];
}

export interface updateProductVariables {
  product: ProductInput
  image?: any
}

export interface updateProductData {
  AdminProductUpdate: Product;
}

export interface createProductVariables {
  product: ProductCreateInput
  image?: File
}

export interface createProductData {
  AdminProductCreate: Product;
}

export interface removeProductVariables {
  productId: string
}

export interface removeProductData {
  AdminProductRemove: boolean;
}

export interface upadeteProductImageVariables {
  _id: string;
  image: File
}

export interface upadeteProductImageData {
  AdminProductImageUpdate: boolean;
}