import { useLazyQuery, useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { adminOrderReprintData, adminOrderReprintVariables, ADMIN_ORDER_REPRINT } from "../../storage/queries/order"
import { Order, ProductOrderTypes, ProductTypes } from "../../storage/types"
import { Button, Loading } from "../../ui"

type OrderProps = {
    order?: Order
}


const transformProductOrderTypes = (productType: ProductOrderTypes, quantity: number) => {
    switch (productType) {
        case ProductOrderTypes.kg:
            return `${quantity} kg`
        case ProductOrderTypes.piece:
            return quantity
        case ProductOrderTypes.box:
            return (quantity === 1)? `1 cassetta`: `${quantity} cassette`
        case ProductOrderTypes.vs:
            return (quantity === 1)? `1 vaschetta`: `${quantity} vaschette`
        default:
            return quantity
    }
}

export const OrderView = (props: OrderProps) => {
    const [orderReprint, { error: orderReprintError, loading: orderReprintLoading }] = useMutation<
        adminOrderReprintData,
        adminOrderReprintVariables
    >(ADMIN_ORDER_REPRINT);

    const [info, setInfo] = useState<string>("")
    const [isReprinting, setIsReprinting] = useState(false)
    useEffect(() => {
        if (props.order) {
            setInfo(``)
            setIsReprinting(false)
        }
    }, [props.order])

    if (!props.order) return <div>Seleziona un ordine</div> 

    const productInfo = (props.order.info && props.order.info !=="")? <div>Info: {props.order.info}</div> : <div>Il cliente non ha lasciato info aggiuntive</div>
    const productList = props.order.products.map((product) => <div key={product.product._id} >{transformProductOrderTypes(product.product.orderType, product.quantity)} {product.product.name}</div>)

    const handleReprintOrder = async () => {
        setIsReprinting(true)
        if (props.order?._id) {
            console.log(`Reprint order ${props.order._id}`)
            const respOrderReprint  = await orderReprint({ variables: { orderId: props.order._id } })
            console.log(respOrderReprint)
            if (respOrderReprint && 
                respOrderReprint.data && 
                respOrderReprint.data.AdminOrderReprint && 
                respOrderReprint.data.AdminOrderReprint === true) {
                setInfo('Ordine ristampato')
            } else {
                if (respOrderReprint.errors) console.log(respOrderReprint.errors)
                setInfo('Errore in ristampa')
            }
        } else {
            setInfo('Ordine non trovato')
            console.error(`Order not present`)
        }
        setIsReprinting(false)
    }

    return (<div className="text-center text-lg border-2 w-full rounded-lg h-full">
        Ordine ricevuto da <label className="font-bold">{(props.order.user && props.order.user.basicInfo && props.order.user.basicInfo.name)? props.order.user.basicInfo.name : 'Sconosciuto'}</label> in data <label className="font-bold">{new Date(props.order.createdAt).toLocaleString('it-IT')}</label>
        <div className="flex flex-col text-left p-2">
            {productList}
        </div>
        <div>
            {productInfo}
        </div>
        <div className="flex pt-2 px-2 flex-col justify-center">
            {(isReprinting) ?<Loading /> : <Button size="md" color="blue" onClick={handleReprintOrder}>Ristampa ordine</Button> }
            {info}
        </div>
        </div>)
}